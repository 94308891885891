import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    fabricator: null,
    pageName: null,
    companyStyles: null,
  },
  mutations: {
    setFabricator(state, fabricator) {
      state.fabricator = fabricator;
    },
    setPageName(state, name) {
      state.pageName = name;
    },
    setCompanyStyling(state, styles) {
      state.companyStyles = styles;
    },
  },
  actions: {
    async loadCustomerStyle({ dispatch }) {
      const customer = await window.touch.customerGet();
      const currencies = await window.touch.commonGetCurrencies();
      if (customer) {
        try {
          customer.currency = currencies.find(
            (currency) => currency.id === customer.financial.currencyId,
          ).code;
        } catch {
          try {
            customer.currency = currencies.filter((currency) => currency.isDefault)[0].code;
          } catch {
            customer.currency = 'GBP';
          }
        }
      }
      await dispatch('user/setCustomer', customer, { root: true });
    },
    async getCompanyStyles({ state, commit, rootState }) {
      let companyId = rootState?.user?.customer?.companyId;
      if (window.VUE_APP_INSTALLATION_TYPE === 'business') {
        const customerId = await window.touch.staffGetOrganisationCustomerId();
        companyId = (await window.touch.customerGet(customerId, true))?.companyId;
      } else {
        companyId = (await window.touch.customerGet())?.companyId;
      }
      if (companyId) {
        const styles = await window.touch.getCompanyStyling(companyId);
        commit('setCompanyStyling', styles);
      } else if (!state.companyStyles) {
        return state.fabricator?.styling;
      }
      return state.companyStyles;
    },
    async loadFabricatorStyle({ commit }) {
      const details = await window.touch.commonOrganisationDetails();
      commit('setFabricator', details);
    },
    async refresh({ dispatch }) {
      dispatch('loadFabricatorStyle');

      if (window.touch.hasToken() && window.VUE_APP_INSTALLATION_TYPE !== 'business') {
        dispatch('loadCustomerStyle');
      }
    },
    async updateCompanyStyles(
      { state, rootState },
      {
        enquiryWelcomeImage = undefined,
        enquiryCompletionImage = undefined,
        virtualHomeBanner = undefined,
        virtualHomeBackground = undefined,
        portalPageLoginImage = undefined,
        portalPageLoginSecondaryImage = undefined,
        portalPageLoginHeader = undefined,
        portalPageSubHeader = undefined,
        portalPageLoginWelcomeText = undefined,
        enquiryHeader = undefined,
        enquirySubHeader = undefined,
        enquiryWelcome = undefined,
        thankYouText = undefined,
        thankYouHeadingText = undefined,
        thankYouSubHeadingText = undefined,
        enquiryPDFFirstImage = undefined,
        enquiryPDFLastImage = undefined,
        enquiryPdfFooterHeader = undefined,
        enquiryPdfFooterText = undefined,
        quotePDFFirstImage = undefined,
        quotePDFLastImage = undefined,
        emailEquiryImage = undefined,
        enquiryEmailInstallerSubject = undefined,
        enquiryEmailInstallerBodyText = undefined,
        enquiryPageDesignNames = undefined,
        enquiryEmailDealerConfirmationText = undefined,
        enquiryEmailBrandedConfirmationText = undefined,
        siteTitle = undefined,
      },
    ) {
      let companyId = rootState?.user?.customer?.companyId;
      if (window.VUE_APP_INSTALLATION_TYPE === 'business') {
        const customerId = await window.touch.staffGetOrganisationCustomerId();
        companyId = (await window.touch.customerGet(customerId, true))?.companyId;
      }
      if (companyId !== null) {
        await window.touch.setCompanyStyling(
          companyId,
          enquiryWelcomeImage,
          enquiryCompletionImage,
          virtualHomeBanner,
          virtualHomeBackground,
          portalPageLoginImage,
          portalPageLoginSecondaryImage,
          portalPageLoginHeader,
          portalPageSubHeader,
          portalPageLoginWelcomeText,
          enquiryHeader,
          enquirySubHeader,
          enquiryWelcome,
          enquiryPDFFirstImage,
          enquiryPDFLastImage,
          enquiryPdfFooterHeader,
          enquiryPdfFooterText,
          quotePDFFirstImage,
          quotePDFLastImage,
          emailEquiryImage,
          enquiryEmailInstallerSubject,
          enquiryEmailInstallerBodyText,
          enquiryPageDesignNames,
          enquiryEmailDealerConfirmationText,
          enquiryEmailBrandedConfirmationText,
          siteTitle,
          thankYouText,
          thankYouHeadingText,
          thankYouSubHeadingText,
        );
      }
    },
    async deleteCompanyStyling(
      { dispatch, commit, state, rootState },
      {
        enquiryWelcomeImage = undefined,
        enquiryCompletionImage = undefined,
        virtualHomeBanner = undefined,
        virtualHomeBackground = undefined,
        portalPageLoginImage = undefined,
        portalPageLoginSecondaryImage = undefined,
        enquiryPDFFirstImage = undefined,
        enquiryPDFLastImage = undefined,
        quotePDFFirstImage = undefined,
        quotePDFLastImage = undefined,
        emailEquiryImage = undefined,
      },
    ) {
      let companyId = rootState?.user?.customer?.companyId;
      if (window.VUE_APP_INSTALLATION_TYPE === 'business') {
        const customerId = await window.touch.staffGetOrganisationCustomerId();
        companyId = (await window.touch.customerGet(customerId, true))?.companyId;
      }
      if (companyId !== null) {
        await window.touch.deleteCompanyStyling(
          companyId,
          enquiryWelcomeImage,
          enquiryCompletionImage,
          virtualHomeBanner,
          virtualHomeBackground,
          portalPageLoginImage,
          portalPageLoginSecondaryImage,
          enquiryPDFFirstImage,
          enquiryPDFLastImage,
          quotePDFFirstImage,
          quotePDFLastImage,
          emailEquiryImage,
        );
        commit('setCompanyStyling', null);
      }
    },
  },
  getters: {
    isCompanyStylingEnabled() {
      return true;
    },
    back_to_site_link(state) {
      try {
        const url = state.fabricator.web;

        return !url.includes('//') ? `//${url}` : url;
      } catch (error) {
        return false;
      }
    },
    fabricator_logo(state) {
      try {
        return `${window.touch.imageBaseURL}${state.fabricator.styling.logo}`;
      } catch (error) {
        // @todo log the error here
        return '/images/logo.svg';
      }
    },
    fabricator_name(state) {
      try {
        return `${state.fabricator.name}`;
      } catch (error) {
        // @todo log the error here
        return 'Touch';
      }
    },
    page_title(state) {
      let installationType = 'Portal';
      if (window.VUE_APP_OVERRIDE === 'vendor') {
        installationType = 'Vendor';
      }
      if (window.VUE_APP_INSTALLATION_TYPE === 'business') {
        installationType = 'Business';
      }
      try {
        const pageTitle = state.fabricator.siteTitle
          ? state.fabricator.siteTitle
          : i18n.t(`title.Touch ${installationType}`);
        return `${pageTitle} - ${state.pageName} - ${installationType}`;
      } catch (error) {
        return 'Loading';
      }
    },
  },
};
