<template>
  <div class="bg-white rounded-lg mt-5 p-5">
    <div class="mb-5">
      <span class="text-lg"> Your Enquiries</span>
    </div>
    <div class="flex items-center">
      <div
        v-if="touch_portal_installation"
        class="flex items-center mr-5 cursor-pointer"
        :class="{ 'pointer-events-none': quotes_and_enquiries_tab === 'enquiries' }"
        @click="quotes_and_enquiries_tab = 'enquiries'"
      >
        <span class="font-thin" :class="{ 'text-green-600': quotes_and_enquiries_tab === 'enquiries' }">       
          <span class="hidden xs:inline-block">from</span> Branded Mode</span>
        <div
          class="rounded-lg text-white text-center ml-2 px-3 bg-gray-400"
          :class="{ 'bg-green-600': quotes_and_enquiries_tab === 'enquiries' }"
          v-text="branded.count"
        ></div>
      </div>
      <div
        v-if="isDealerNetworkModeAvailable"
        class="flex items-center cursor-pointer"
        :class="{ 'pointer-events-none': quotes_and_enquiries_tab === 'networkenquiries' }"
        @click="quotes_and_enquiries_tab = 'networkenquiries'"
      >
        <span
          class="font-thin"
          :class="{ 'text-green-600': quotes_and_enquiries_tab === 'networkenquiries' }"
        >
          <span class="hidden xs:inline-block">from</span> Dealer Network</span>
        <div
          class="rounded-lg text-white text-center ml-2 px-3 bg-gray-400"
          :class="{ 'bg-green-600': quotes_and_enquiries_tab === 'networkenquiries' }"
          v-text="dealerNetwork.count"
        ></div>
      </div>
    </div>
    <div class="mt-5">
      <loading :loading="loading">
        <template v-if="enquiries.length > 0">
          <div class="border-solid border-t border-gray-500 mb-4"></div>
          <table class="table-auto w-full">
            <thead>
              <tr class="text-left">
                <th class="px-4 font-thin text-gray-500">#</th>
                <th class="px-4 font-thin text-gray-500">Date</th>
                <th class="px-4 font-thin text-gray-500">Customer</th>
                <th class="px-4 font-thin text-gray-500 float-right hidden xs:block">Postcode</th>
              </tr>
            </thead>
            <tbody>
              <router-link
                v-for="enquiry in enquiries"
                :key="enquiry.id"
                class="cursor-pointer hover:bg-gray-200"
                tag="tr"
                :to="
                  touch_business_installation ? `/dealer-network/assign/${enquiry.id}` : enquiry.url
                "
              >
                <td class="px-4 py-1">{{ enquiry.customerCounter }}</td>
                <td class="px-4 py-1">{{ formatDate(enquiry.date) }}</td>
                <td class="px-4 py-1">
                  {{ enquiry.consumer.firstName + " " + enquiry.consumer.lastName }}
                </td>
                <td class="px-4 py-1 float-right text-right hidden xs:block">{{ enquiry.consumer.addressCode }}</td>
              </router-link>
            </tbody>
          </table>
        </template>
        <div v-else class="flex flex-col justify-around flex-grow">
          <div v-if="quotes_and_enquiries_tab === 'enquiries'" class="text-center">
            <div
              v-if="branded_mode_enabled"
              class="flex items-center gap-5 bg-gray-200 rounded-lg p-5 max-w-2xl mx-auto m-5"
            >
              <div class="flex-1">
                <div>
                  <span>Once you have deployed Branded Mode.</span>
                </div>
                <div class="mb-2">
                  <span>Any enquiries you generate will appear here...</span>
                </div>
                <div>
                  <router-link
                    class="text-green-600"
                    to="/touch-store"
                  >
                    Tips on deploying Branded Mode.
                  </router-link>
                </div>
              </div>
              <div class="flex-1">
                <img class="w-full" src="/images/branded-mode-disabled-no-enquiries.png" />
              </div>
            </div>
            <div
              v-else
              class="flex items-center gap-5 bg-gray-200 rounded-lg p-5 max-w-2xl mx-auto m-5"
            >
              <div class="flex-1">
                <div>
                  <span>At the moment you have no enquiries.</span>
                </div>
                <div class="mb-2">
                  <span>Generate enquiries today with Branded Mode...</span>
                </div>
                <div>
                  <router-link
                    class="text-green-600"
                    to="/touch-store"
                  >
                    Start your Free Trial
                  </router-link>
                </div>
              </div>
              <div class="flex-1">
                <img class="w-full" src="/images/branded-mode-no-enquiries.png" />
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <div class="flex items-center gap-5 bg-gray-200 rounded-lg p-5 max-w-2xl mx-auto m-5">
              <div class="flex-1">
                <div>
                  <span>At the moment you have no enquiries.</span>
                </div>
                <div class="mb-2">
                  <span>
                    Contact your Fabricator to become a preferred partner and gain access to FREE enquiries...
                  </span>
                </div>
              </div>
              <div class="flex-1">
                <img class="w-full" src="/images/dealer-mode-no-enquiries.png" />
              </div>
            </div>
          </div>
        </div>
      </loading>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dateHelper from '@/mixins/dateHelper';
import currencyHelper from '@/mixins/currencyHelper';
import jobHelper from '@/mixins/jobHelper';

export default {
  mixins: [dateHelper, currencyHelper, jobHelper],
  data() {
    return {
      quotes_and_enquiries_tab: undefined,
      branded: {
        count: 0,
        enquiries: [],
      },
      dealerNetwork: {
        count: 0,
        enquiries: [],
      },
      loading: true,
      limit: 5,
    };
  },
  computed: {
    ...mapState('auth', {
      isDealerNetworkModeAvailable: 'isDealerNetworkModeAvailable',
    }),
    enquiries() {
      return this.quotes_and_enquiries_tab === 'enquiries'
        ? this.branded.enquiries
        : this.dealerNetwork.enquiries;
    },
  },
  async mounted() {
    this.quotes_and_enquiries_tab = this.touch_portal_installation
      ? 'enquiries'
      : 'networkenquiries';
    this.branded = await this.loadFeed(this.enums.enquirySource.BRANDED_MODE);
    this.dealerNetwork = await this.loadFeed(this.enums.enquirySource.DEALER_NETWORK);
    this.loading = false;
  },
  methods: {
    async loadFeed(enquirySource) {
      this.loading = true;
      const options = {
        limit: this.limit,
        enquirySource,
        statusId: [this.enums.enquiryStatus.ASSIGNED, this.enums.enquiryStatus.ACCEPTED],
      };
      const response = await this.$store.dispatch('touch/loadEnquiries', options);
      return { count: response.total_items, enquiries: response.enquiries };
    },
  },
};
</script>
