<template>
  <div class="flex-1 flex flex-col">
    <div class="bg-blue-100 border p-3 h-full">
      <div class="flex items-center border-b font-bold mb-3 pb-3">
        <div v-if="job?.discountApplied" class="flex gap-2 items-center">
          <span>
            Discounted {{ breakdown }}
          </span>
          <Tooltip v-if="!isCostsHidden">
            <span class="font-thin">
              The discount code 
              <span class="text-green-500">{{ job?.discountCode }}</span> has been applied
              <span v-if="job?.items?.some(x => x.override === true)"> to an item in this quote.</span>
              <span v-else> to the subtotal of this quote.</span>
            </span>
          </Tooltip>
        </div>
        <div v-else-if="job?.overridePrice === true || job?.items?.some(x => x.override === true)" class="flex gap-2 items-center">
          <span>
            Manual {{ breakdown }}
          </span>
          <Tooltip v-if="!isCostsHidden">
            <span v-if="job?.overridePrice === true" class="font-thin">The subtotal has been manually updated</span>
            <span v-else class="font-thin">The price of an item has been manually updated.</span>
          </Tooltip>
        </div>
        <div v-else>
          Calculated {{ breakdown }}
        </div>
        <div class="ml-auto">
          <slot></slot>
        </div>
      </div>
      <table v-if="!isCostsHidden">
        <tbody>
          <tr v-if="job?.discountApplied" class="align-baseline">
            <td class="pb-1 w-32">Discount:</td>
            <td class="text-brand-primary">{{ formatCurrency(touchJob.currency(), touchJob.itemCost() - job.priceBeforeDiscount) }}</td>
          </tr>
          <tr class="align-baseline">
            <td class="pb-1 w-32">Subtotal:</td>
            <td>
              <span v-if="touchJob.calculatedPriceTaxable()">
                <span v-if="job?.discountApplied || job?.overridePrice === true || job?.items?.some(x => x.override === true)" class="line-through opacity-50">
                  {{ formatCurrency(touchJob.currency(), touchJob.calculatedPriceTaxable()) }}
                </span>
              </span>
              <div class="flex items-center gap-2 pb-1">
                <span
                  class="text-brand-primary"
                  v-html="formatCurrency(touchJob.currency(), touchJob.itemCost())"
                ></span>
                <Tooltip v-if="touchJob.deliveryCost() > 0 || touchJob.fittingPrice() > 0 || touchJob.surveyPrice() > 0" class="text-black" :is-left="false">
                  <div class="flex flex-col gap-1 text-white">
                    <span class="opacity-50 mb-2">Subtotal includes:</span>
                    <div v-if="touchJob.deliveryCost() > 0">
                      <span><span
                        v-html="formatCurrency(touchJob.currency(), touchJob.deliveryCost())"
                      ></span>
                        delivery charge</span>
                    </div>
                    <div v-if="touchJob.fittingPrice() > 0">
                      <span><span
                        v-html="formatCurrency(touchJob.currency(), touchJob.fittingPrice())"
                      ></span>
                        fitting charge</span>
                    </div>
                    <div v-if="touchJob.surveyPrice() > 0">
                      <span><span
                        v-html="formatCurrency(touchJob.currency(), touchJob.surveyPrice())"
                      ></span>
                        survey charge</span>
                    </div>
                  </div>
                </Tooltip>
              </div>
            </td>
          </tr>
          <tr>
            <td class="pb-1 w-32">VAT:</td>
            <td
              class="pb-1 text-brand-primary"
              v-html="formatCurrency(touchJob.currency(), touchJob.taxCost())"
            ></td>
          </tr>
          <tr>
            <td class="w-32">Total:</td>
            <td
              class="text-brand-primary"
              v-html="formatCurrency(touchJob.currency(), touchJob.totalCost())"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import currencyHelper from '@/mixins/currencyHelper';
import Tooltip from '@/components/shared/Tooltip.vue';

export default {
  components: {
    Tooltip
  },
  mixins: [currencyHelper],
  props: ['touchJob', 'breakdown', 'isCostsHidden', 'job'],
  data() {
    return {
      isPriceBreakdown: false,
    };
  },
};
</script>

<style scoped>
.info:before {
  content: "";
  position: absolute;
  top: 100%;
  right: 20px;
  width: 0;
  border-top: 20px solid black;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
</style>
