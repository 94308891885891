<template>
  <div
    class="line-item"
    :class="{
      '': !showDetail,
      'pointer-events-none': lineItem.inputType === enums.JobLineItemInputType.ExtraItem,
    }"
    @click.prevent="showDetail = !showDetail"
  >
    <div class="line-item-header">
      <div class="_header-attributes-group">
        <div class="_header-attribute">
          <div v-if="lineItem.inputType === enums.JobLineItemInputType.ExtraItem" class="_label">
            Extra
          </div>
          <div v-else class="_label">Product</div>
          <div class="_description">
            {{ lineItem.description }}
            <span v-if="lineItem.size && item.inputType === enums.JobLineItemInputType.ExtraItem">({{ lineItem.size.split(" ")[0] }})</span>
          </div>
        </div>
      </div>
      <div class="_header-attributes-group">
        <div
          class="_header-attribute"
        >
          <div class="_label">Location</div>
          <div class="_description">{{ lineItem.location }}</div>
        </div>
        <div
          class="_header-attribute"
        >
          <div class="_label">Colour (External)</div>
          <div class="_description">{{ lineItem.colorExternal }}</div>
        </div>
        <div
          class="_header-attribute"
        >
          <div class="_label">Colour (Internal)</div>
          <div class="_description">{{ lineItem.colorInternal }}</div>
        </div>
      </div>
      <div class="_header-attributes-group">
        <div class="_header-attribute _item-no">
          <div class="_label">Item No.</div>
          <div class="_description">{{ itemNumber }} of {{ totalItems }}</div>
        </div>
        <div class="_header-attribute _item-qty">
          <div class="_label">Qty.</div>
          <div class="_description">{{ lineItem.quantity }}</div>
        </div>
        <div v-if="showPrice" class="_header-attribute _item-price">
          <div class="_label">Item {{ breakdown }}</div>
          <div class="_description">
            <div
              v-if="lineItem.override && lineItem.calculatedPrice"
              class="line-through opacity-50"
              v-html="formatCurrency(job.currency(), item.calculatedPrice / item.quantity)"
            ></div>
            <div
              v-if="lineItem.quantity > 1"
              :class="{ 'line-through': job.job.overridePrice }"
              v-html="formatCurrency(job.currency(), job.itemPriceForItem(lineItem) / lineItem.quantity)"
            ></div>
            <div
              v-else
              :class="{ 'line-through': job.job.overridePrice }"
              v-html="formatCurrency(job.currency(), job.itemPriceForItem(lineItem))"
            ></div>
          </div>
        </div>
        <div v-if="showPrice" class="_header-attribute _item-price">
          <div class="_label">Total {{ breakdown }}</div>
          <div class="_description">
            <div
              v-if="lineItem.override && lineItem.calculatedPrice"
              class="line-through opacity-50"
              v-html="formatCurrency(job.currency(), lineItem.calculatedPrice)"
            ></div>
            <div
              :class="{ 'line-through': job.job.overridePrice }"
              v-html="formatCurrency(job.currency(), job.itemPriceForItem(lineItem))"
            ></div>
          </div>
        </div>
        <div
          v-if="lineItem.inputType !== enums.JobLineItemInputType.ExtraItem"
          class="_show-detail-control"
        >
          <i v-if="showDetail" class="fa fa-caret-down"></i>
          <i v-else class="fa fa-caret-right"></i>
        </div>
      </div>
    </div>
    <line-item-extras
      v-if="!showDetail && extras && extras.length > 0"
      :job="job"
      :item="lineItem"
      :extras="extras"
      :show-detail="showDetail"
    ></line-item-extras>
    <div v-if="!isEnquiryLineItem && lineItem.issues && lineItem.issues.length > 0">
      <div class="bg-red-600 text-white text-center">
        <div class="w-full bg-red-600 text-white border-t border-white">
          <div
            v-for="(issue, i) in lineItem.issues"
            :key="i"
            class="p-3"
            v-text="issue.description"
          ></div>
        </div>
      </div>
    </div>
    <loading :loading="isLoading" class="pb-5">
      <div
        v-if="showDetail"
        class="line-item-detail"
        :class="{ 'block xs:flex': showDetail, 'hidden print:flex': !showDetail }"
      >
        <div class="line-item-summary">
          <div v-if="lineItem.dimensions && lineItem.dimensions.length > 0" class="item-summary-group">
            <header class="group-title">
              <h2>Measurements</h2>
            </header>
            <div v-for="spec in lineItem.dimensions" :key="spec.id" class="group-attributes">
              <div class="attribute-label">
                <strong>{{ spec.description }}:</strong>
              </div>
              <div class="attribute-value">{{ spec.value }}mm</div>
            </div>
          </div>
          <div
            v-for="(specGroup, title) in specificationGroups"
            :key="title"
            class="item-summary-group"
          >
            <header class="group-title">
              <h2>{{ title }}</h2>
            </header>
            <div v-for="(spec, key) in specGroup" :key="`spec_group_${key}`" class="group-attributes">
              <div class="attribute-label">
                <strong>{{ spec.headingName }}</strong>
              </div>
              <div class="attribute-value">
                {{ spec.itemName }}
              </div>
            </div>
          </div>
          <line-item-extras
            v-if="showDetail && extras && extras.length > 0"
            :job="job"
            :item="lineItem"
            :extras="extras"
            :show-detail="showDetail"
          ></line-item-extras>
        </div>
        <div class="line-item-images">
          <div v-for="(image, key) in sortedImages" :key="`item_image_${key}`" class="text-center">
            <div class="xs:bg-gray-200 xs:border xs:border-gray-300 xs:p-10">
              <show-svg
                v-if="showDetail"
                :branded-mode="isEnquiryLineItem"
                :show-decoration-overrides="!isEnquiryLineItem"
                :show-sizes="showSizes"
                :svg="image.svg"
                class="w-full"
              ></show-svg>
            </div>
            <div class="p-3">
              {{ image_types[image.type] }}
            </div>
          </div>
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import LineItemExtras from '@/components/shared/job/LineItemExtras.vue';
import currencyHelper from '@/mixins/currencyHelper';

export default {
  components: {
    'line-item-extras': LineItemExtras,
  },
  mixins: [currencyHelper],
  props: [
    'job',
    'itemNumber',
    'totalItems',
    'item',
    'showPrice',
    'isEnquiryLineItem',
    'showSizes',
    'extras',
    'breakdown',
  ],
  data() {
    return {
      showDetail: false,
      image_types: window.imageTypes,
      lineItem: undefined,
      isLoading: false
    };
  },
  computed: {
    isLeadGen() {
      return (
        this.$store.state.style.fabricator.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
    sortedImages() {
      let items = this.lineItem.images.slice().sort((a, b) => a.type - b.type);
      if (this.isLeadGen) {
        return items.filter(({ type }) => this.image_types[type] !== 'Glass Schematic');
      }
      return items;
    },
    specificationGroups() {
      const spec = this.lineItem.specification.filter((specItem) => specItem.omitFromSummary === false);
      const groups = spec.reduce((obj, specItem) => {
        const { groupName } = specItem;

        // eslint-disable-next-line no-prototype-builtins
        if (!obj.hasOwnProperty(groupName)) {
          // eslint-disable-next-line no-param-reassign
          obj[groupName] = [];
        }

        obj[groupName].push(specItem);

        return obj;
      }, {});

      const toRename = [];

      Object.entries(groups).forEach(([key, value]) => {
        // groups[key] = 1;
        value.forEach((item, index) => {
          if (groups[key].filter((group) => group.name === item.name).length > 1) {
            if (item.components && item.components.length > 0) {
              toRename.push({
                key,
                index,
                newName: `${groups[key][index].name} (${item.components[0].description})`,
              });
            }
          }
        });
      });

      toRename.forEach((entry) => {
        groups[entry.key][entry.index].name = entry.newName;
      });

      return groups;
    },
  },
  watch: {
     async 'showDetail' (showDetail) {
      if (showDetail && this.lineItem.specification.length === 0) {
        this.isLoading = true
        const fullItem = await window.touch.contractJobLineItemDetails(
          this.job.job.contractId,
          this.job.job.jobKey,
          this.lineItem.key,
          this.$store.state.auth.processingLevel,
          this.job.job.exchangeRate,
        );
        Object.keys(fullItem).forEach((key) => {
          this.$set(this.lineItem, key, fullItem[key]);
        });
        this.isLoading = false
      }
    }
  },
  created () {
    this.lineItem = this.item
    this.lineItem.specification = []
    this.lineItem.images = []
  },
};
</script>
