<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [$t('link.My Branded Mode Account'), '/dealer-network-setup'],
          ['Company Details'],
        ]"
      />
    </template>
    <template #header_page_title> Company Details & Settings </template>
    <Menu />
    <Company
      setup="Dealer Network"
      :customer-id="$store.state.user.customer.customerId"
      @afterSaved="afterSaved"
      @staffUpdateEnquiryExpirationTime="staffUpdateEnquiryExpirationTime"
    >
      <template #email_address>
        <div class="text-gray-600 text-base mt-3">
          We'll email this address with details of any rejected enquiries.
        </div>
      </template>
      <template #enquiries-overdue-limit>
        <div class="flex flex-col pb-10 mb-10 border-b">
          <div class="flex flex-col justify-around text-base mb-3">
            <div>Time Limit for Assigned Enquiries (hrs)</div>
          </div>
          <input
            v-if="enquiryOverdueLimit"
            v-model="enquiryOverdueLimit"
            type="number"
            :disabled="!is_admin_user || demo_mode"
            class="form-control max-w-md"
            placeholder="This defaults to 24 hours"
          />
          <div class="text-gray-600 text-base mt-3">
            When you assign a Dealer Network enquire how long will the customer have to action the
            enquiry. Add a number of hours.
          </div>
          <div class="text-gray-600 text-base mt-3">
            If not actioned in this time the enquiry will be available to assign to another customer.
          </div>
        </div>
      </template>
    </Company>
  </touch-layout>
</template>

<script>
import Company from '@/components/shared/setup/Company.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/shared/setup/company/Menu.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Company,
    Breadcrumbs,
    Menu,
  },
  data () {
    return {
      enquiryOverdueLimit: undefined
    }
  },
  async mounted () {
    this.enquiryOverdueLimit = (await window.touch.staffGetEnquiryExpirationTime())?.enquiresExpireAfter;
  },
  methods: {
    ...mapActions({
      setCustomer: 'user/setCustomer',
    }),
    async afterSaved() {
      await this.setCustomer(this.$store.state.user.customer.customerId);
    },
    async staffUpdateEnquiryExpirationTime() {
      await window.touch.staffUpdateEnquiryExpirationTime(this.enquiryOverdueLimit);
    },
  },
};
</script>
