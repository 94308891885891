<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], [$t('link.My Branded Mode Account'), '/setup'], ['Prices']]"
      />
    </template>
    <template #header_page_title> Prices </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <div
          class="border border-purple-700 bg-purple-700 text-white rounded-full text-center w-6 h-6 flex flex-col justify-around cursor-pointer hover:bg-white hover:text-purple-700"
          @click="test_help_box_open = !test_help_box_open"
        >
          <i class="fal fa-info-circle"></i>
        </div>
      </div>
    </template>
    <div
      id="pricing_index_scrolling_outer_wrapper"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch xs:p-10"
    >
      <div class="mx-auto relative" style="max-width: 1550px">
        <div class="flex flex-col-reverse xs:flex-row">
          <div class="flex-grow">
            <keep-alive>
              <router-view v-if="!loading" :bus="bus"></router-view>
            </keep-alive>
          </div>
          <div
            class="md:block md:ml-10 md:mb-0 md:relative mt-10 md:mt-0 bg-white md:bg-transparent"
            :class="{
              'absolute z-20 block top-0 right-0': test_help_box_open,
              hidden: !test_help_box_open,
            }"
          >
            <how-to-setup-pricing
              class="vendor-setup-help-sidebar"
              @set-filter="bus.$emit('set-filter', $event)"
            ></how-to-setup-pricing>
            <div class="bg-green-200 p-6 lg:p-10 mt-6 text-center vendor-setup-help-sidebar">
              <div class="text-xl mb-3">
                <strong>Test your pricing</strong>
              </div>
              <div>
                Process a a quote using the quote and survey designer. Select the Price Calculation
                Breakdown icon next to the price as shown below.
              </div>
              <div class="my-6">
                <img
                  src="/images/vendor-pricing-setup-guide.png"
                  class="mx-auto"
                  style="max-height: 150px"
                />
              </div>
              <div>
                <router-link
                  to="/new-quote"
                  class="rounded-full bg-white border border-black text-center p-3 w-full"
                  target="_blank"
                >
                  <strong>Process a Test Quote</strong>
                </router-link>
              </div>
            </div>
            <div class="flex justify-center">
              <info-popup
                :id="'vendor_settings_pricing:test_your_pricing'"
                class=""
                :info_message="'Once you have added your pricing you can then use the price breakdown calculator within the sales and survey designer by clicking the magnifying glass, as pictured in the test your pricing example.'"
                :next_id="'vendor_settings_pricing:how_to_setup_pricing'"
              ></info-popup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && is_admin_user"
      class="bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
    >
      <div class="flex flex-col xs:flex-row justify-between">
        <div class="text-red-600 flex flex-col justify-around text-center xs:text-left">
          <span v-show="has_unsaved_changes"> You have unsaved changes. </span>
        </div>
        <div v-if="!demo_mode" class="mt-2 xs:mt-0">
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="saveSettings()">
            {{ $t('Save all Changes') }}
          </button>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import Vue from 'vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import HowToSetupPricing from '@/components/portal/setup/pricing/HowToSetupPricing.vue';

export default {
  components: {
    Breadcrumbs,
    'how-to-setup-pricing': HowToSetupPricing,
  },
  data() {
    return {
      children: {},
      bus: new Vue(),
      loading: true,
      test_help_box_open: false,
    };
  },
  computed: {
    active_section() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);
    },
    has_unsaved_changes() {
      return this._.find(this.children, (child) => !child.isSaved) !== undefined;
    },
    has_validation_errors() {
      return this._.find(this.children, (child) => !child.isValid) !== undefined;
    },
  },
  async mounted() {
    this.loading = true;
    this.bus.$on('register', (details) => {
      this.$set(this.children, details.name, details);
    });
    this.bus.$on('update', (details) => {
      Object.entries(details).forEach(([key, entry]) => {
        this.$set(this.children[details.name], key, entry);
      });
    });
    this.bus.$on('saved', (details) => {
      Object.entries(details).forEach(([key, entry]) => {
        this.$set(this.children[details.name], key, entry);
      });
      this.afterSaved();
    });
    this.loading = false;
  },
  methods: {
    getHtmlForValidationErrors() {
      let html = '';
      Object.entries(this.children).forEach(([key, entry]) => {
        if (this.children[key].isValid && !this.children[key].isSaved) {
          html += `<div class="mb-6 text-green-600"><strong>${key}</strong> Saved Successfully</div>`;
        }
        if (!this.children[key].isValid) {
          html += `<div class="mb-2 text-red-600"><strong>${key}</strong> had the following errors:</div>`;
          html += `<ul class="mb-3">${Object.values(entry.errors).join('</li><li>')}</ul>`;
        }
      });
      return html;
    },
    async saveSettings() {
      await this.bus.$emit('save');
      if (this.has_validation_errors) {
        const html = this.getHtmlForValidationErrors();
        this.alertBox().fire({
          title: 'Some settings were not saved',
          icon: 'error',
          html,
        });
        this.loading = false;
      }
    },
    async afterSaved() {
      if (!this.has_validation_errors) {
        if (this.$store.state.basket.basket.length > 0) {
          this.$store.dispatch('basket/refresh', true);
          this.alertBox()
            .fire({
              title: 'Settings Updated',
              showDenyButton: true,
              denyButtonText: 'Resume Basket',
              confirmButtonText: 'Close',
            })
            .then(async (result) => {
              if (!(result.isDismissed || result.isConfirmed)) {
                this.routerPush('/basket');
              }
            });
        } else {
          this.alertBox().fire({
            title: 'Settings Updated',
            icon: 'success',
          });
        }
      }
      this.loading = false;
    },
  },
};
</script>
