<template>
  <div class="flex flex-wrap bg-white border m-5 xs:m-10 relative pr-16">
    <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
      <div class="font-bold">Company</div>
      <div class="flex w-full items-center">
        <div
          v-if="!company_logo_missing"
          class="rounded-full border flex flex-col justify-around overflow-hidden relative mr-3"
          style="width: 35px; height: 35px"
        >
          <img
            :id="'fabricator_details_logo'"
            :src="style.fabricator_logo"
            class="m-auto"
            @error="company_logo_missing = true"
            @load="positionAvatar"
          />
        </div>
        <div class="flex-grow">
          <div class="h-10 flex flex-col justify-around">{{ name }}</div>
        </div>
      </div>
    </div>
    <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
      <div class="font-bold">Contact Number</div>
      <div class="h-10 flex flex-col justify-around">{{ phone }}</div>
    </div>
    <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
      <div class="font-bold">Email</div>
      <div class="h-10 flex flex-col justify-around">{{ email }}</div>
    </div>
    <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
      <div class="font-bold">Website</div>
      <div class="h-10 flex flex-col justify-around">{{ web }}</div>
    </div>
    <div
      v-if="customer.eCommerceIntegration.companyRegNumber"
      class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4"
    >
      <div class="font-bold">Company Registration Number</div>
      <div class="h-10 flex flex-col justify-around">
        {{ customer.eCommerceIntegration.companyRegNumber }}
      </div>
    </div>
    <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
      <div class="font-bold">Address</div>
      <div class="h-10 flex flex-col justify-around">
        {{
          customer.eCommerceIntegration.addressId
            ? formattedAddress(selected_address)
            : "You have no saved addresses"
        }}
      </div>
    </div>
    <div class="absolute bottom-0 right-0 p-5">
      <div
        v-if="is_admin_user"
        class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
        @click="editing = true"
      >
        <i class="fa fa-pencil"></i>
      </div>
    </div>
    <portal v-if="editing" to="portal_popup">
      <modal-window :modal_open="editing" title="Edit Company Details" @close="cancelChanges">
        <form @submit="submitForm">
          <div class="grid xs:grid-cols-2 gap-5">
            <div>
              <div class="font-medium">Company</div>
              <input v-model="name" class="form-control" />
            </div>
            <div>
              <div class="font-medium">Contact Number</div>
              <input v-model="phone" class="form-control" />
            </div>
            <div>
              <div class="font-medium">Email</div>
              <input v-model="email" class="form-control" />
            </div>
            <div>
              <div class="font-medium">Website</div>
              <input v-model="web" class="form-control" />
            </div>
            <div>
              <div class="font-medium">Company Registration Number</div>
              <input
                v-model="customer.eCommerceIntegration.companyRegNumber"
                class="form-control"
              />
            </div>
          </div>
          <div class="flex flex-wrap gap-5 mt-5">
            <div class="flex-1">
              <div class="w-full flex">
                <vue-dropzone
                  id="dropzone"
                  ref="fileUploadDropzone"
                  class="stealth-vue-dropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-file-added="fileAddedToDropZone"
                  @vdropzone-removed-file="fileRemovedFromDropZone"
                >
                  <div class="font-medium">Logo</div>
                  <div v-if="!company_logo_missing">
                    <div class="flex flex-col justify-around">
                      <div>
                        <img
                          :id="'editing_fabricator_details_logo'"
                          :src="style.fabricator_logo"
                          style="max-height: 100px"
                          @error="company_logo_missing = true"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="text-green-600 h-10 flex flex-col justify-around cursor-pointer">
                    Upload a new logo
                  </div>
                </vue-dropzone>
              </div>
            </div>
            <div class="flex-1">
              <div class="w-full mt-4">
                <div class="font-medium flex gap-2">
                  <span>Address</span>
                  <strong
                    class="cursor-pointer underline text-blue-600"
                    @click="show_address_popup = true"
                  >Edit</strong>
                </div>
                <div v-if="customer.eCommerceIntegration.addressId">
                  {{ formattedAddress(selected_address) }}
                </div>
                <div v-else class="text-red-600">You have no saved addresses</div>
              </div>
              <modal-window
                v-if="show_address_popup"
                :modal_open="show_address_popup"
                title="Your Address"
                @close="show_address_popup = false"
              >
                <popup-address-selection
                  :addresses="customer.addresses"
                  :selected-address="customer.eCommerceIntegration.addressId"
                  :customer-id="customer.customerId"
                  @select-address="customer.eCommerceIntegration.addressId = $event"
                  @new-address="newAddress"
                  @close="show_address_popup = false"
                ></popup-address-selection>
              </modal-window>
            </div>
          </div>
          <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
            <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="cancelChanges">
              Cancel
            </button>
            <button class="btn-action btn-lg" type="submit">Save Changes</button>
          </div>
        </form>
      </modal-window>
    </portal>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { mapActions } from 'vuex';
import PopupAddressSelection from '@/components/shared/address/PopupAddressSelection.vue';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
    'popup-address-selection': PopupAddressSelection,
  },
  data() {
    return {
      editing: false,
      name: this.$store.state.style.fabricator.name,
      web: this.$store.state.style.fabricator.web,
      phone: this.$store.state.style.fabricator.phone,
      email: this.$store.state.style.fabricator.email,
      customer: this.$store.state.user.customer,
      file_ready: false,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
      new_logo_image: null,
      company_logo_missing: false,
      show_address_popup: false,
    };
  },
  computed: {
    edited() {
      return (
        this.file_ready ||
        ((this.name || this.$store.state.style.fabricator.name) &&
          this.name !== this.$store.state.style.fabricator.name) ||
        ((this.web || this.$store.state.style.fabricator.web) &&
          this.web !== this.$store.state.style.fabricator.web) ||
        ((this.email || this.$store.state.style.fabricator.email) &&
          this.email !== this.$store.state.style.fabricator.email) ||
        ((this.phone || this.$store.state.style.fabricator.phone) &&
          this.phone !== this.$store.state.style.fabricator.phone)
      );
    },
    selected_address() {
      if (this.customer.eCommerceIntegration.addressId) {
        return this.customer.addresses.filter(
          (address) => address.id === this.customer.eCommerceIntegration.addressId,
        )[0];
      }
      return {};
    },
  },
  methods: {
    ...mapActions({
      customerUpdate: 'user/customerUpdateRaw',
    }),
    async imageToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    async fileAddedToDropZone(uploadedFile) {
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png',
        });
        return;
      }
      this.new_logo_file = uploadedFile;
      this.file_ready = true;
    },
    fileRemovedFromDropZone() {
      this.new_logo_file = null;
      this.file_ready = false;
    },
    async submitForm(event) {
      event.preventDefault();
      this.$store
        .dispatch('touch/staffUpdateOrganisationDetails', {
          name: this.name,
          web: this.web,
          phone: this.phone,
          email: this.email,
          file: this.file_ready ? await this.imageToBase64(this.new_logo_file) : null,
        })
        .then(async () => {
          this.$store.dispatch('style/loadFabricatorStyle');
          this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
          this.editing = false;
          await this.customerUpdate({
            CustomerId: this.customer.customerId,
            eCommerceIntegration: {
              companyRegNumber: this.customer.eCommerceIntegration.companyRegNumber,
              addressId: this.customer.eCommerceIntegration.addressId,
            },
          });
          await this.$store.dispatch('user/setCustomer', this.customer.customerId);
        });
    },
    cancelChanges() {
      this.editing = false;
      this.name = this.$store.state.style.fabricator.name;
      this.web = this.$store.state.style.fabricator.web;
      this.phone = this.$store.state.style.fabricator.phone;
      this.email = this.$store.state.style.fabricator.email;
      this.new_logo_image = null;
    },
    newAddress({ addresses, addressId }) {
      this.customer.addresses = addresses;
      this.customer.eCommerceIntegration.addressId = addressId;
    },
    formattedAddress(address) {
      return [
        address.line1,
        address.line2,
        address.line3,
        address.town,
        address.county,
        address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
  },
};
</script>
