<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [$t('link.My Branded Mode Account'), '/setup'],
          ['Custom Styles'],
        ]"
      />
    </template>
    <template #header_page_title> Custom Styles </template>
    <Menu />
    <CustomStyles ref="customStyles" setup="Branded Mode" @setInstaller="setInstaller">
      <template #custom-installer-email>
        <div v-if="customStyles" class="flex flex-col mb-10 border-b pb-10 max-w-3xl">
          <div class="flex flex-col justify-around text-base">
            <strong>Customer Email Confirmation of New Branded Mode Enquiry</strong>
          </div>
          <div class="text-gray-600 text-base mt-3">
            <strong>Location:</strong>
            This is the email that will be sent to your customer to confirm that you have received their enquiry.
          </div>
          <div class="flex flex-col mt-5">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Email Subject</div>
            </div>
            <div class="">
              <input
                v-model="customStyles.settings.enquiryEmailInstallerSubject"
                type="text"
                class="form-control max-w-md"
              />
            </div>
          </div>
          <div class="flex flex-col mt-5">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Email Body</div>
            </div>
            <div class="">
              <textarea
                v-model="customStyles.settings.enquiryEmailInstallerBodyText"
                rows="4"
                class="form-control max-w-md"
              ></textarea>
            </div>
          </div>
        </div>
      </template>
    </CustomStyles>
  </touch-layout>
</template>

<script>
import CustomStyles from '@/components/shared/setup/CustomStyles.vue';
import Menu from '@/components/shared/setup/customStyles/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    CustomStyles,
    Breadcrumbs,
  },
  data () {
    return {
      customStyles: undefined
    }
  },
  mounted () {
    this.customStyles = this.$refs.customStyles
  },
  methods: {
    setInstaller(installerSubject, installerBodyText) {
      this.$refs.customStyles.settings.enquiryEmailInstallerSubject = installerSubject;
      this.$refs.customStyles.settings.enquiryEmailInstallerBodyText = installerBodyText;
    },
  }
};
</script>
